<template>
  <div class="Logado">
    <div class="container">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <img class="rounded mx-auto d-block" alt="Vue logo" src="@/assets/images/logo.png" />
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logado",
  setup(){
    //console.log(process.env.VUE_APP_AUTH_API)
  }
};
</script>
